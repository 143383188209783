import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import { MenuBarStyles } from '../../../Globalstylesheet';
import Banner from './Banner.jsx';

const MenuBar = () => {
  const [value, setValue] = useState(0);

  const handleTabs = useCallback((e, value) => {
    setValue(value);
  }, []);

  const location = useLocation().pathname;

  return (
    <AppBar position='fixed' sx={MenuBarStyles.menuStyle}>
      <Toolbar sx={MenuBarStyles.toolBar}>
        <Tabs value={value} onChange={handleTabs}>
          <Tab
            sx={MenuBarStyles.tabsTitle}
            label="Account"
            component={Link}
            to="/amp"
            selected={location === '/amp' || location === '/accountconfig'}
          />
          {/* <Tab
            sx={MenuBarStyles.tabsTitle}
            label="Application Catalogue"
            component={Link}
            to="/applications"
            selected={location === '/applications'}
          />
          <Tab
            sx={MenuBarStyles.tabsTitle}
            label="Service Manual"
            component={Link}
            to="/servicemanual"
            selected={location === '/servicemanual'}
          /> */}
        </Tabs>
      </Toolbar>
      <Banner />
    </AppBar>
  );
};

export default MenuBar;
