import React, {  useCallback, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarStyles } from '../../Globalstylesheet';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({ message, type, autoHideTime }) => {
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  // const[autoHidden, setAutoHidden] = useState(null)

  const { open, vertical, horizontal } = state;

  useEffect(() => {
    // Open the Snackbar when the component is called
    setState({ ...state, open: true });

    // Automatically close the Snackbar after 3 seconds (adjust as needed)
    // if(autoHide){
      const timer = setTimeout(() => {
        setState({ ...state, open: false });
      }, autoHideTime ? autoHideTime * 1000 : 3000);
  
      // Clean up the timer when the component unmounts or when it is called again
      return () => clearTimeout(timer);
    // }

  }, [message, type]);

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, open: false });
  }, []);


  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      action={action}
    >
      <Alert onClose={handleClose} severity={type} sx= {type === 'info' ? SnackbarStyles?.infoStyles : SnackbarStyles?.defaultStyles}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
