import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const Login = React.lazy(() => import('../components/login/Login'));
const ApplicationCatalogue = React.lazy(() =>
  import('../components/Applications/ApplicationCatalogue'),
);
const Account = React.lazy(() => import('../components/account/Account'));
const ServiceManual = React.lazy(() =>
  import('../components/ServiceManual/ServiceManual'),
);
const NotFound = React.lazy(() => import('../components/NotFound'));
const ModifyEhrConfig = React.lazy(() =>
  import('../components/account/ehr-config/ModifyEhrConfig'),
);
const LinearStepper = React.lazy(() =>
  import('../components/stepper/LinearStepper'),
);
const ModifyAccountConfig = React.lazy(() =>
  import('../components/account/ehr-config/ModifyAccountConfig'),
);
const AddApp = React.lazy(() =>
  import('../components/ApplicationConfig/ApplicationConfig'),
);

const EditEhr = React.lazy(() =>
  import('../components/account/ehr-config/ModifyEhrConfig'),
);
const Roletag = React.lazy(() =>
  import('../components/Elements/RoleTagInput'),
);

const Ehr = React.lazy(() =>
  import('../components/account/ehr-config/ModifyEhrConfig'),
);



const routes = (
    <Suspense
      fallback={
        <div style={{ margin: '0 auto', top: '50%' }}>
          <CircularProgress />
        </div>
      }
    >
      <Routes>
        <Route path="/amp" element={<Account />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/applications" element={<ApplicationCatalogue />}></Route>
        <Route path="/servicemanual" element={<ServiceManual />}></Route>
        <Route path="/accountconfig" element={<LinearStepper step={0} />}></Route>
        {/* <Route path="/accconfig/:id" element={<LinearStepper step={1} />}></Route> */}
        <Route path="/modifyehr/:id" element={<ModifyEhrConfig />}></Route>
        <Route path="/addApp/:id" element={<AddApp />}></Route>
        <Route path="/addApp" element={<AddApp />}></Route>
        <Route
          path="/modifyaccountconfig/:id"
          element={<ModifyAccountConfig />}
        ></Route>
        <Route
          path="/modify_Ehr/:id"
          element={<EditEhr />}
        ></Route>

        <Route path="/roletag" element={<Roletag />}></Route>
        <Route path="/ehr" element={<Ehr />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
);

export default routes;
