import { Grid} from '@mui/material';
import React from 'react';
import { footerStyles } from '../../../Globalstylesheet';

const Footer = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={footerStyles.footer}
    >
    </Grid>
  );
};

export default Footer;
