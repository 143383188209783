import { Grid } from '@mui/material'
import React from 'react'
import { BannerStyles } from './Banner'
import messages from '../../../en.json';

function Banner() {
    return (
        <Grid style={BannerStyles.infoMessage}>
           {messages.infoMessage}
        </Grid>
    )
}

export default Banner;
