import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import { authTokenService } from './api/service/AuthTokenService';
import { remoteUrl } from './api/url/UrlList';
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import SnackBar from './components/Elements/SnackBar';
import { loginRequest, msalConfig } from './authConfig';
import SessionTimeout from './components/SessionTimeout';
import ProtectedComponent from './components/Auth/ProtectedComponent';
import Authorization from './components/Auth/RequireAuth';
import {
  ehrVendorList,
  hlSevenVersions,
  fhirVersions,
  hl7Events,
  appNames
} from './shared/dropdown-api/LookUpTableApi';
import { OnScreenTexts as Msg } from './utils/constants';

export const parseChallenges = (header) => {
  const schemeSeparator = header.indexOf(' ');
  const challenges = header.substring(schemeSeparator + 1).split(', ');
  const challengeMap = {};

  challenges.forEach((challenge) => {
    const [key, value] = challenge.split('=');
    challengeMap[key.trim()] = window.decodeURI(value.replace(/(^"|"$)/g, ''));
  });

  return challengeMap;
}

function App() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [rolesPermissionCheck, setRolesPermissionCheck] = useState(false);
  let activeAccount, userName;
  if (instance) {
    activeAccount = instance.getActiveAccount();
    userName = `${activeAccount?.name?.split(", ")[1]} ${activeAccount?.name?.split(", ")[0]}`
  }

  const handleLoginRedirect = () => {
    instance.acquireTokenRedirect({ loginRequest }).catch((error) => console.log(error));
  }

  const AuthLayout = Authorization(Layout, window.APP_LAUNCH_TYPE == 'standalone' ? ['beckman.admin'] : activeAccount?.idTokenClaims['roles']);

  if (inProgress === 'none' && !isAuthenticated && window.APP_LAUNCH_TYPE != 'standalone') {
    handleLoginRedirect();
  }

  useEffect(() => {
    if (window.APP_LAUNCH_TYPE === 'standalone') {
      getOfflineToken();
    } else {
      if (isAuthenticated) {
        getAuthToken();
      }
    }
  }, [isAuthenticated]);

  const getAuthToken = () => {

    const accessTokenRequest = {
      scopes: [`api://${window?.AZURE_CLIENT_ID}/.default`],
      account: activeAccount,
      claims: activeAccount && sessionStorage.getItem(`cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${new URL(remoteUrl.cdsAuthTokenApi).hostname}`)
        ? window.atob(sessionStorage.getItem(`cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${new URL(remoteUrl.cdsAuthTokenApi).hostname}`))
        : undefined,
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(function (accessTokenResponse) {
        let accessToken = accessTokenResponse.accessToken,
          idToken = accessTokenResponse.idToken;
        sessionStorage.setItem('access_token', accessToken);
        sessionStorage.setItem('id_token', idToken);

        authTokenService
          .getCdsAuthToken()
          .then((response) => {
            if (response.status === 401) {
              if (response.headers.get('WWW-Authenticate')) {
                const authenticateHeader = response.headers.get('WWW-Authenticate');
                const claimsChallenge = parseChallenges(authenticateHeader);
                sessionStorage.setItem(
                  `cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${new URL(remoteUrl.cdsAuthTokenApi).hostname}`,
                  claimsChallenge.claims,
                );

                throw new Error(`claims_challenge_occurred`);
              }

              throw new Error(`Unauthorized: ${response.status}`);
            }
            else if (response.status === 200) {
              sessionStorage.setItem('appRolesPermissions', response?.data?.result?.appRolesAndPermissions);
              setRolesPermissionCheck(true);
              hl7Events();
              ehrVendorList();
              hlSevenVersions();
              fhirVersions();
              appNames();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(function (error) {
        console.log(error.response);

      });

  };

  const getOfflineToken = () => {
    authTokenService.
      getOfflineService().then((response) => {
        sessionStorage.setItem('access_token', response.data.access_token);
        sessionStorage.setItem('id_token', response.data.id_token);
        sessionStorage.setItem('appRolesPermissions', response.data.appRolesPermissions);
      })
  }

  return (
    <>
      {window.APP_LAUNCH_TYPE !== 'standalone' ? <AuthenticatedTemplate>
        <ProtectedComponent />
        {rolesPermissionCheck && <SnackBar message={`${userName} ${Msg.logInMessage}`} type={Msg.toasterType} />}
        <Router basename="/beckman-global">{rolesPermissionCheck && <AuthLayout></AuthLayout>}</Router>
      </AuthenticatedTemplate> :
        <Router basename="/beckman-global">
          <AuthLayout></AuthLayout>
        </Router>
      }
      <SessionTimeout />
    </>
  );
}

export default App;
