import uuid from 'react-uuid';
import { dropDownService } from '../../api/service/DropDownService';

export const hl7Events = () => {
  dropDownService
    .getHlEvents()
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem(
          'hl7events',
          JSON.stringify(
            response.data.result.map((v) => {
              return v.hl7eventname;
            }),
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const ehrVendorList = () => {
  dropDownService
    .ehrVendorList()
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem(
          'ehrvendor',
          JSON.stringify(
            response.data.result.map((v) => {
              return {
                value: v.ehrtypename,
                label: v.ehrtypename,
              };
            }),
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err?.respose?.statusText);
    });
};

export const hlSevenVersions = () => {
  dropDownService
    .hlSevenVersions()
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem(
          'hl7version',
          JSON.stringify(
            response.data.result.map((v) => {
              return {
                value: v.hl7versionname,
                label: v.hl7versionname,
              };
            }),
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err.respose.statusText);
    });
};

export const fhirVersions = () => {
  dropDownService
    .fhirVersions()
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem(
          'fhirversion',
          JSON.stringify(
            response.data.result.map((v) => {
              return {
                value: v.fhirversionname,
                label: v.fhirversionname,
              };
            }),
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err?.respose?.statusText);
    });
};

export const ehrRoles = (id) => {
  dropDownService
    .ehrRoles(id)
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem(
          'ehrRoles',
          JSON.stringify(
            response?.data?.result.map((v) => {
              return {
                id: uuid(),
                status: v.status,
                ehrRoleId: v.id,
                ehrRoles: v.name,
                type: v.type,
                value: v.id,
                label: v.name,
              };
            }),
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err?.respose?.statusText);
    });
};

export const appRoles = (id) => {
  dropDownService
    .appRoles(id)
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem(
          'appRoles',
          JSON.stringify(
            response?.data?.result?.map((v) => {
              return {
                id: uuid(),
                status: v.status,
                roleId: v.id,
                appRoles: v.name,
                type: v.type,
                value: v.id,
                label: v.name,
              };
            }),
          ),
        );
      }
    })
    .catch((err) => {
      console.log(err?.respose?.statusText);
    });
};


export const appNames = () => {
  dropDownService.getApplication()
    .then((response) => {
      if (response.status === 200) {
        let appresult = response?.data?.result.map(function (item) {
          if (item?.status && item?.type?.toLowerCase() === 'application')
            return { id: item.resourceId, type: item.type, status: item.status, value: item.name, label: item.name, version: item.version, name: item.name }
          else
            return null
        }).filter(item => item !== null)


        const resultMap = new Map();

        appresult.forEach((item) => {
          const { label, version, value, type, status, id, name } = item;
          if (!resultMap.has(label)) {
            resultMap.set(value, {
              versions: [],
              status,
              value,
              label,
              type,
              name,
              id
            });
          }
          resultMap.get(label).versions.push(version);
        });


        const finalResult = Array.from(resultMap, ([label, { versions, value, type, status, id, name }]) => ({
          label,
          versions,
          value, 
          type, 
          name,
          status, 
          id
        }));
        sessionStorage.setItem('applicationName', JSON.stringify(finalResult));

      } else if(response.status === 500) {
        console.log('500 error')
      }
    }).catch((err) => {
      console.log(err);
    });


}


export const moduleTypes = () => {
  dropDownService.getModule()
    .then((response) => {
      if (response.status === 200) {
        let appresult = response?.data?.result.map(function (item) {
          return { id: uuid(), moduleType: item.moduleType, moduleTypesId: item.moduleTypeId }
        })
        sessionStorage.setItem('module', JSON.stringify(appresult));

      }
    })
}