export const localUrl = {
    baseUrl: 'REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT' in window && window.REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT,
    testTokenUrl: 'REACT_APP_API_ENDPOINT_GETCDSTESTTOKEN' in window && window.REACT_APP_API_ENDPOINT_GETCDSTESTTOKEN,

    /**__________loginPage___________*/
    login: '/login',

    /**________authToken__________*/
    authToken: '/authToken',
    status: `/ResourceDeploymentTraceDetails`,


    /**___________EHR_____________*/
    ehrList: '/ehrAccounts',
    createEhr: `/ehr`,
    saveEhr: '/saveEhr',
    ehrConfig: `/ehrConfig`,
    createEhrConfig: `/createEhrConfig`,
    ehrDetails: '/ehrDetails/',
    createEhrRole: '/ehrrole',

    /***__________account__________*/
    createAccount: `/createAccount`,
    saveAccount: `/saveAccount`,
    accountDetails: '/account/',
    getAccountDetails: '/getaccountDetails',
    account: '/account',
    accountConfig: `/accountConfig`,
    createAccountConfig: `/createAccountConfig`,
    onboardStatus: `/onboardStatus`,
    accountsList: '/accountsList',
    /**_________application________*/
    applications: '/applications',
    appConfig: `/appConfig`,
    createAppConfig: `/createAppConfig`,
    saveAppConfig: `/saveAppConfig`,
    createAppRuleConfig: `/createAppRuleConfig`,

    /**___________common___________*/
    configType: `/configType`,
    configSubType: `/configSubType`,
    particularAllConfig: `particularAllConfig`,
    //completeOnBoard: '/onboard',
    hl7Events: `/hlEvents`,
    hl7Versions: '/hlVersions',
    ehrTypes: '/ehrTypes',
    fhirVersions: '/fhirVersions',
    ehrRoles: '/ehrRoles',
    appRoles: '/appRoles',
    getPurge: '/getPurge',
    postPurge: '/postPurge',
    ehrrolemapping: `/rolemapping`,
    getUomConfig: '/getUomConfig',
    postUomConfig: '/postUomConfig',
    getThresholdConfig: '/getThresholdConfig',
    postThresholdConfig: '/postThresholdConfig',
    thresholdRange: '/thresholdRange',
    /**_____________Resource Store_____________*/
    resourcestore: `/ResourceStore`,
    moduleTypes: '/ModuleTypes',
    getDhRules: '/getDhRules',
    postDhRules: '/postDhRules',
    biomarkerslist: '/biomarkersList'
};



/**_______________Remote_URL_Config_________________*/
const remoteUrlForm = {
    apiDoaminName: 'REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT' in window && window.REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT + '/api/',
    versionOne: 'v1/',
    portal: 'cdsplatform/',
    configuration: 'AccountManagement/',
    resource: 'ResourceRegistration/ResourceStore',
    accessManagement: 'access/',
};

const baseUrl = `${remoteUrlForm.apiDoaminName}${remoteUrlForm.versionOne}${remoteUrlForm.portal}${remoteUrlForm.configuration}`,
    cdsAuthTokenUrl = `${remoteUrlForm.apiDoaminName}${remoteUrlForm.versionOne}${remoteUrlForm.portal}${remoteUrlForm.accessManagement}`;


export const remoteUrl = {
    login: `${baseUrl}`,

    /**__________Token___________*/
    authToken: `${baseUrl}GetToken`,
    cdsAuthTokenApi: `${cdsAuthTokenUrl}RolesAndPermission`,
    testTokenUrl: 'REACT_APP_API_ENDPOINT_GETCDSTESTTOKEN' in window && window.REACT_APP_API_ENDPOINT_GETCDSTESTTOKEN,
    /**_____________EHR____________*/
    ehrList: `${baseUrl}configurations`, //get ehr-list
    createEhr: `${baseUrl}ehr`, //create ehr
    createEhrConfig: `${baseUrl}configuration`, //create ehr-config (onboarding)
    ehrConfig: `${baseUrl}` /**7.ehr config */ ,
    ehrDetails: `${baseUrl}/`,

    /***______________Account______________*/
    accountList: `${baseUrl}account`, //get all accounts
    accountDetails: `${baseUrl}account?accountid=`, //get specific account details
    createAccount: `${baseUrl}account`, // create Account
    saveAccountConfig: `${baseUrl}account`, //create account-config(onboarding)
    createAccountConfig: `${baseUrl}configuration`, //create account-config(onboarding)
    accountConfig: `${baseUrl}`, //account configuration
    onboardStatus: `${baseUrl}/account?accountid=`, //AccountDeployementstatus,
    status: `${baseUrl}ResourceDeploymentTrace`,
    getPurge: `${baseUrl}`,
    postPurge: `${baseUrl}`,
    uomConfig: `${baseUrl}`,
    getThreshold: `${baseUrl}`,
    postThreshold: `${baseUrl}`,
    dhRules: `${baseUrl}`,
    biomarkersList: `${baseUrl}`,

    /**_____________Application_____________*/
    appConfig: `${baseUrl}4/configuration?key=App_Config&application=mace` /**6.app config */ ,
    applications: `${baseUrl}applications` /**4.get Applications catalogue */ ,
    createAppConfig: `${baseUrl}configuration` /**12.create app-config */ ,
    createAppRuleConfig: `${baseUrl}configuration` /**14.create app-rule-Config */ ,
    hl7Events: `${baseUrl}HL7Events`,
    hl7Versions: `${baseUrl}Hl7Versions`,
    ehrTypes: `${baseUrl}EhrTypes`,
    fhirVersions: `${baseUrl}fhirVersion`,
    ehrRoles: `${baseUrl}EhrRole`,
    appRoles: `${baseUrl}Role`,
    createEhrRole: `${baseUrl}ehrrole`,
    deleteEhrRole: `${baseUrl}`,


    /**_____________Role mapping_____________*/
    ehrrolemapping: `${baseUrl}ehrrolemapping`,

    /**_____________Resource Store_____________*/
    resourcestore: `${baseUrl}ResourceStore`,
    moduleTypes: `${baseUrl}ModuleTypes`,

    /**_______Deployment Request________________ */
    deploymentRequest: `${baseUrl}deploymentrequest`,


    /**___________________common___________________*/

    configType: `${baseUrl}configurationtype`, // configuration type
    configSubType: `${baseUrl}1/configurationsubtype`, // configuration sub-type
    particularAllConfig: `${baseUrl}1/configuration?key=Account_Config&application=AMI&version=2`, // getparticular/All configurations
    // completeOnBoard: `${baseUrl}onboardStatus`, // complete onboard
};