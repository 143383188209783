import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import moment from 'moment';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";


const SessionTimeout = () => {
  const [events] = useState(["load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"]);
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  const logout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/amp",
      mainWindowRedirectUri: "/"
    }).catch(e => {
      console.error(e);
    });
  }

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      warningInactive();
    }, 1000);
  };

  // warning timer
  let warningInactive = () => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = parseInt(window.REACT_APP_AUTO_LOGOUT_TIME); // need to give time out time here - 2min sample
      const popTime = 1;

      const diff = moment.duration(moment().diff(moment(sessionStorage.getItem('lastTimeStamp'))));
      const minPast = diff.minutes();

      if (minPast === popTime) {
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        logout();
      }
    }, 60000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
    setOpen(false);
  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);


  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return <Fragment />;
};

export default SessionTimeout;