import { axiosService } from './GenericService';
import { localUrl, remoteUrl } from '../url/UrlList';
import { config as appConfiguration } from '../config/Configuration';

class DropDownService {
    appMode = appConfiguration.localMode;

    _config = {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Control-Allow-Origin': '*',
        },
    };

    /** lookup hl7-events */
    getHlEvents() {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.hl7Events}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.hl7Events}`;
            return axiosService.get(url, this._config);
        }
    }

    /** lookup hl7versions */
    hlSevenVersions() {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.hl7Versions}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.hl7Versions}`;
            return axiosService.get(url, this._config);
        }
    }

    /**ehrtypes */
    ehrVendorList() {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.ehrTypes}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.ehrTypes}`;
            return axiosService.get(url, this._config);
        }
    }

    /** fhir versions */
    fhirVersions() {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.fhirVersions}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.fhirVersions}`;
            return axiosService.get(url, this._config);
        }
    }

    /** ehr role */
    ehrRoles(id) {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.ehrRoles}`;
            this._config.headers.accountId = id;
            return axiosService.get(url, this._config);
        } else {
            const url = `${remoteUrl.ehrRoles}`;
            this._config.headers.accountId = id;
            return axiosService.get(url, this._config);
        }
    }

    /**app role */
    appRoles(id) {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.appRoles}`;
            this._config.headers.accountId = id;
            return axiosService.get(url, this._config);
        } else {
            const url = `${remoteUrl.appRoles}`;
            this._config.headers.accountId = id;
            return axiosService.get(url, this._config);
        }
    }


    getApplication(accountId) {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.resourcestore}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.resourcestore}`;
            this._config.headers.accountId = accountId;
            return axiosService.get(url, this._config);
        }
    }


    getModule() {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.moduleTypes}`;
            return axiosService.get(url)
        } else {
            const url = `${remoteUrl.moduleTypes}`;
            return axiosService.get(url, this._config);
        }
    }
}



const dropDownService = new DropDownService();
export { dropDownService };