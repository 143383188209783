import React, { useMemo } from 'react';
import { UserContext } from "./Provider";

function UserContextRef({ children, authorizeduser, allowedRoles }) {
    const contextValue = useMemo(() => ({
        authorizeduser: authorizeduser,
        userRole: allowedRoles,
    }), [authorizeduser, allowedRoles]);

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export default UserContextRef;
