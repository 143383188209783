import React, { createContext, useState, useContext, useMemo } from 'react';
import SnackBar from './SnackBar';

/**
 * create Error context
 * to get Error Object
 */
export const ErrorCtx = createContext();

const ErrorContext = ({ children }) => {
  const [apiError, setApiError] = useState({ code: '', message: '' });
  const [isError, setIsError] = useState(false);



  const memoizedFunc = useMemo(() => {
    return { apiError, setApiError, isError, setIsError };
  }, [apiError, setApiError, isError, setIsError]); 

  return (
    <ErrorCtx.Provider value={memoizedFunc}>
      {isError ? (
        <SnackBar
          message={`${apiError?.code}: ${apiError?.message}`}
          type="error"
        />
      ) : (
        ''
      )}
      {children}
    </ErrorCtx.Provider>
  );
};

/** make use of useContext & export context
 * to access it in the required components
 */
export const ErrorState = () => {
  return useContext(ErrorCtx);
};

export default ErrorContext;
