
import React, { useState, useEffect } from 'react';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import ConfigNotLoaded from "./components/ConfigNotLoaded";
import ErrorContext from './components/Elements/ErrorContext';

const AppWrapper = ({ msalInstance }) => {

    const [isConfigLoaded, setIsConfigLoaded] = useState(false);

    const loadConfig = async () => {
        if (('REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT' in window && 'REACT_APP_MSAL_CLIENTID' in window && 'REACT_APP_MSAL_AUTHORITY' in window && 'REACT_APP_MSAL_REDIRECTURI' in window && 'REACT_APP_AUTO_LOGOUT_TIME' in window)
            && ((window?.REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT !== null || window?.REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT !== undefined || window?.REACT_APP_API_ENDPOINT_ACCOUNTMANAGEMENT !== "")
                && (window?.REACT_APP_MSAL_CLIENTID !== null || window?.REACT_APP_MSAL_CLIENTID !== undefined || window?.REACT_APP_MSAL_CLIENTID !== "")
                && (window?.REACT_APP_MSAL_AUTHORITY !== null || window?.REACT_APP_MSAL_AUTHORITY !== undefined || window?.REACT_APP_MSAL_AUTHORITY !== "")
                && (window?.REACT_APP_MSAL_REDIRECTURI !== null || window?.REACT_APP_MSAL_REDIRECTURI !== undefined || window?.REACT_APP_MSAL_REDIRECTURI !== "")
                && (window?.REACT_APP_AUTO_LOGOUT_TIME !== null || window?.REACT_APP_AUTO_LOGOUT_TIME !== undefined || window?.REACT_APP_AUTO_LOGOUT_TIME !== "")
            )) {
            setIsConfigLoaded(true);
        }
        else {
            setIsConfigLoaded(false);
        }
    };

    useEffect(() => {
        loadConfig();
    }, []);


    // Determine the component to render based on the config loading status and APP_LAUNCH_TYPE
    const renderAppContent = () => {
        if (!isConfigLoaded) {
            return <ConfigNotLoaded />;
        }

        return window.APP_LAUNCH_TYPE === 'standalone' ? (
            <App />
        ) : (
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        );
    };

    return (
        <ErrorContext>
            {renderAppContent()}
        </ErrorContext>
    )
}

export default AppWrapper;