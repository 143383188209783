import { Box } from '@mui/material';
import React from 'react';
import routes from '../../../Routes/routes';
import { shellStyles } from '../../../Globalstylesheet';


const ShellComponent = () => {


  return <Box sx={shellStyles}>{routes}</Box>;
};

export default ShellComponent;
