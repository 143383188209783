export const PieChartStyles = {
  Mainbox: {
    display: 'flex',
    flexdirection: 'row',
    justify: 'right',
    justifyContent: 'right',
    alignItems: 'right',
  },
  box: {
    display: 'flex',
    justify: 'left',
    justifyContent: 'left',
    alignItems: 'left',
    flexdirection: 'column',
    height: '85%',
    mt: 2,
    ml: 30,
  },

  pageheader: {
    mt: 10,
    ml: 30,
    fontSize: 24,
    height: '10%',
    width: '100%',
    justify: 'left',
    justifyContent: 'left',
    alignItems: 'left',
  },
  pageheaderDesp: {
    mt: 5,
    ml: 30,
    height: '5%',
    width: '100%',
    justify: 'left',
    justifyContent: 'left',
    alignItems: 'left',
  },
  pieGridTable: {
    width: '50%',
    justify: 'left',
    alignItems: 'left',
    justifyContent: 'left',
  },
  gridTable: {
    width: 300,
  },
  gridTableRow: {
    height: 0.5,
  },
  gridTablecellIcons: {
    width: 5,
    borderBottom: 'none',
  },
  gridTablecell: {
    borderBottom: 'none',
  },
  gridListCheck: {
    color: 'green',
  },
  gridListCross: {
    color: 'red',
  },
  gridListRestore: {
    color: '#4d4dff',
  },
  pieGrid: {
    width: '50%',
    mr: 30,
    mt: 0,
  },
};
export const modifyStyles = {
  Tabstyles: {
    pt: 5,
  },
  EhrconfigTab: {
    border: '1px solid black',
    pb: 5,
    
  },
  AppconfigTab: {
    border: '1px solid black',
    p: 8,
  },
  DataHarmonization: {
    border: '1px solid black',
    p: 1,
  },
  AppconfigBox: {
    mt: 0,mb:2,display: 'flex',flexDirection:'row',justifyContent:'right'
  },
  ManageRolesTab:{
    mt:2
  }

};

export const ehrListStyles = {
  tableContainer: {
    height: 380,
    '& .super-app-theme--header': {
      backgroundColor: '#1976d2',
      color: 'white',
    },
  },
  buttonContainer:{
    height: 120,
    '& .super-app-theme--header': {
      backgroundColor: '#1976d2',
      color: 'white',
    },
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },

  ehrTitle: {
    ml: 0.5,
    mt: 2,
  },

  btnText: {
    textTransform: 'none',
    width: '16.6%',
  },

  titleBtnWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  ehrDataGrid: {
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
};

export const tabStyles = {
  tabTitle: {
    fontSize: '13px',
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
    marginLeft: 0,
  },
  menuWrapper: {
    boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
  },
};

export const ehrConfigStyles = {
  titleBtnWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
};

export const rowStyles = {
  tableRow: {
    '& > *': { borderBottom: 'none' },
    fontSize: '12px',
  },
  StatusText: {
    '& a:hover i': {
      color: 'red',
      fontWeight: 700,
    },
  },
  tableRowStyle: {
    '& th': {
      //  color: 'white',
      //border: '1px solid #1E90FF',
      padding: '8px',
      fontSize: '1em',
    },
    '& td': {
      padding: '12px',
      fontSize: '1em',
    },
  },

  ehrCell: {
    height: '5px',
    width: '10%',
    '& th': {
      padding: '8px',
      fontSize: '1em',
    },
    '& td': {
      padding: '1px',
      fontSize: '1em',
    },
  },

  ehrActionBtnCell: {
    height: '5px',
    width: '10%',
    '& th': {
      padding: '8px',
      fontSize: '1em',
    },
    '& td': {
      padding: '1px',
      fontSize: '1em',
    },
  },
  custCell: {
    height: '5px',
    width: '15%',
    '& th': {
      padding: '8px',
      fontSize: '1em',
      border: '0',
    },
    '& td': {
      padding: '1px',
      fontSize: '1em',
    },
  },

  tableCellButton: {
    minWidth: '35px',
    maxWidth: '105px',
    maxHeight: '30px',
    minHeight: '35px',
    fontSize: '12px',
    textTransform: 'none',
  },

  toolTip: {
    bgcolor: 'white',
    p: 2,
    fontSize: '1em',
    border: '1px solid #1E90FF',
    textAlign: 'left',
  },

  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  boxStyle: {
    margin: 1,
  },

  scrollBar: {
    overflowY: 'scroll',
  },
};

export const paginationStyles = {
  boxStyles: {
    flexShrink: 0,
    ml: 2.5,
  },
};

export const gridStyles = {
  paper: {
    width: '70%',
    overflow: 'hidden',
    m: 5,
  },

  ehrTitle: {
    // 'font-size': '20px',
    // 'font-weight': 'bold',
  },
  container: {
    maxHeight: 400,
    // overflowY: 'scroll',
  },

  tableRow: {
    color: '#FFFFFF',
    '& th': {
      color: '#FFFFFF',
      backgroundColor: '#1976d2',
      padding: '10px',
    },
  },
  cell: {
    color: '#FFFFFF',
  },

  scrollBar: {
    overflowY: 'scroll',
  },

  toolBar: {
    alignItems: 'flex-start',
    marginRight: 0,
    paddingRight: '5px',
    justifyContent: 'space-between',
  },

  toolBarGrid: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
  },

  searchBar: {
    width: '20%',
    '& .MuiInputBase-root': {
      height: 38,
    },
    marginBottom: '10px',
  },

  newEhrBtn: {
    fontSize: 's',
    textTransform: 'none',
    marginBottom: '10px',
    marginRight: '0px',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem'
  },
  gridContainer: {
    pt: 5,
    justifyContent: 'space-between',
    spacing: 119,
  },

  accountListContainer: {
    width: '95%',
  },
};

export const TagInputStyles = {
  '& .tags-input-container': {
    border: '1px solid #000',
    padding: '.5em',
    'border-radius': '3px',
    display: 'flex',
    alignItems: 'center',
    'flex-wrap': 'wrap',
    gap: '.5em',
    width: '50%',
    margin: 0,
  },
  '& .tag - item': {
    'background-color': 'rgb(218, 216, 216)',
    display: 'inline - block',
    padding: '.5em .75em',
  },
  '& .tag - item.close': {
    height: '20px',
    width: '20px',
    'background - color': 'rgb(48, 48, 48)',
    color: '#fff',
    'border - radius': '50%',
    display: 'inline - flex',
    justifyContent: 'center',
    alignItems: 'center',
    'margin - left': '.5em',
    fontSize: '18px',
    cursor: 'pointer',
  },
  '& .tags - input': {
    'flex - grow': 1,
    padding: '.5em 0',
    border: 'none',
    outline: 'none',
  },

  '& .tag - item.tag - text': {
    cursor: 'pointer',
  },
};

export const Element_TextFieldStyles = {
  fontSize: '12px',
  color: 'red',
};

export const multiSelectStyles = {
  label: { fontSize: '14px' },
  formControl: { width: 200 },
  chip: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
};

export const Element_gridStyles = {
  tableContainer: {
    height: 380,
    // width: '60%',
    '& .super-app-theme--header': {
      backgroundColor: '#1976d2',
      color: 'white',
    },
  },
  pagination: {
    marginTop: 2,
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
};

export const Element_Fileupload = {
  fileUploadWrapper: { display: 'flex' },
  highLightIcon: { color: 'primary',margin:"auto", marginLeft:"15px" },
  uploadMsg: { fontSize: '0.8em', color: 'red', margin:"auto" },
  uploadBtnWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inlineBlock"
  },
  
  btn: {
    color: "#fff",
    backgroundColor: "#1976d2",
    padding: "0",
    borderRadius: "3px",
    fontSize: "15px",
    width:"100px",
    height:"35px",
    border:"none",
    margin:"auto 10px",
    marginLeft:"0px"
  },
  
  FileCls: {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
  }
};

export const Element_EHRbuttonBoxStyles = {
  box: {
    marginTop: '5%',
  },
  commonButton: {
    mt: '2',
    minWidth: '64px',
    height: '40px',
  },

  marginLeftSmall: {
    marginLeft: '2%',
  },
};

export const Element_checkbox = {
  // mt: 2,
};

export const Element_buttonSectionstyle = {
  box: {
    // marginTop: '5%',
  },
  Button: {
    mt: '2',
    marginLeft: '3%',
    width: '120px',
    height: '50px',
  },
  backButton: {
    mt: '2',
    width: '120px',
    height: '50px',
  },
};

export const Element_buttonstyle = {
  addIcon: {
    mt: 0,
    color: '#1976d2',
    alignContent: 'left',
  },
  deleteIcon: {
    mt: 4,
    color: '#ff3300',
    alignContent: 'left',
  },
};

export const Element_AddAppStyles = {
  addIcon: {
    mt: 0,
    color: '#4da6ff',
    alignContent: 'left',
  },
  box: {
    display: 'grid',
    columnGap: 1,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};
export const AppCatalogueStyles = {
  marginLeft: 10,
  mt: 5,
};
export const ApplicationStyles = {
  datagrid: {
    marginLeft: 10,
    marginTop: 3,
    marginRight: 10,
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  tableContainer: {
    height: 380,
    // width: '60%',
    '& .super-app-theme--header': {
      backgroundColor: '#1976d2',
      color: 'white',
    },
  },
};
export const manageRolesTabStyles = {
  box: {
    mt: 1,
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
};
export const manageRolesContainerStyles = {
  box: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
};
export const ApplicationTabStyles = {
  box: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};

export const AccountConfigStyles = {
  box: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};
export const summaryStyles = {
  wrapperBox: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  appListWrapper: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '0px 0px 10px 0px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  paperStyle: {
    backgroundColor: '#eeeb',
    boxShadow: 'none',
    paddingLeft: '15px',
  },

  configTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    mt: 3,
    pb: 1,
  },
  subHeader: { fontSize: '14px', fontWeight: 'bold' },
  configFieldName: { fontSize: '12px' },
  configFieldValue: { fontSize: '12px', marginLeft: '5px' },
  alertIcon: { fill: '#FC0' },
  backButton: { mr: '20px', mt: '30px', textTransform: 'none' },
  confirmButton: { mt: '30px', textTransform: 'none' },
  cancelButton: { ml: '20px', mt: '30px', textTransform: 'none' },
};

export const SummarytextData = {
  pageTitle: 'Summary',
  alertText:
    'Are you sure to proceed with complete onboarding with below details:',
  ehrConfigHeader: 'EHR Configuration',
  accountConfigHeader: ' Account Configuration',
  appConfigHeader: ' Appliation Configuration',
  backButton: 'Back',
  confirmButton: 'Confirm',
  cancelButton: 'Cancel',
};

export const stepperStyles = {
  stepper: {
    '& .MuiStepLabel-root .Mui-completed': {
      color: '#4EC33D',
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
      color: 'black',
    },
  },
  stepLabel: {
    mb: '10px',
  },
  stepperHeader: {
    mt: 3,
  },
};
export const ServiceManalStyles = {
  comingSoonBox: {
    flexGrow: 1,
    display: { xs: 'none', sm: 'flex', md: 'flex', xl: 'flex' },
    marginTop: 5,
    marginLeft: 4,
    marginRight: 4,
    borderLeft: '1px solid #fff',
    height: 60,
    backgroundColor: 'primary.dark',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    fontSize: '30px !important',
    fontWeight: 700,
  },
  Box: {
    marginTop: 2,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px !important',
  },
};

export const footerStyles = {
  footer: {
    position: 'fixed',
    bottom: 0,
    padding: '0px 4%',
    backgroundColor: '#1976d2',
    height: '45px',
    zIndex: 999,
  },
  footerText: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#fff',
  },
};

export const HeaderStyles = {
  Box1: {
    flexGrow: 0,
    marginLeft: 3,
    borderLeft: '1px solid #fff',
    height: 20,
  },
  MgmntConsoleBox: {
    flexGrow: 1,
    display: { xs: 'none', sm: 'flex', md: 'flex', xl: 'flex' },
    justifyContent: 'center',
  },
  toolbar: {
    minHeight: '50px !important',
  },
  menu: {
    mt: '45px',
  },
};
export const MenuBarStyles = {
  menuStyle: {
    mt: 6,
    background: 'white',
    boxShadow: '0px 0px 1px 0px #00000087',
  },
  tabsTitle: {
    // fontWeight: '600',
    color: 'black',
    fontSize: { xs: '13px', sm: '13px', md: '13px', xl: '14px' },
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  },
  toolBar: {
    minHeight: '50px !important',
  },
};
export const shellStyles = {
  mb: 10,
  mt: 15,
};
export const modalStyles = {
  buttons: {
    display: 'flex',
    justifyContent: 'start',
    marginLeft: '18px',
    marginBottom: '10px',
  },
  modalTitle: { background: '#1976d2', color: '#fff' },
  containerOne: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 30px 0px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  container: {
    display: 'grid',
    columnGap: 2,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};

export const headingStyles = {
  header: { fontSize: '1.1rem' },
};

export const unauthorizedStyles = {
  header: { display: 'inline-block', paddingTop: '5%'}
}

export const AddDataHarmConfigStyles = {
  AddButton: {
    float: 'right',
    textTransform:'none',
  },
  boxWrapper:{
    marginTop:'40px'
  },
  box: {
    display: 'grid',
    columnGap: 3,
    rowGap: 1,
    padding: '10px 10px 10px 0px',
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
}

export const SnackbarStyles = {
  defaultStyles : {margin:'3rem', widht:'100%'},
  infoStyles : {margin:'3rem', widht:'100%', backgroundColor:'#1976d2'}
}


export const AboutStyles ={
  aboutContainerStyles : {    paddingTop: '0px', paddingRight: '15px', paddingBottom: 'unset', paddingLeft: '15px', background: 'rgb(239, 239, 239)', fontFamily: 'Arial, sans-serif' },
  softwareParentStyle: {fontFamily:'Arial', fontStyle:'normal', fontWeight:800, fontSize:'14px',  lineHeight:'12px', color:'#181818' },
  softwareChildStyle: {fontFamily:'Arial', fontStyle:'normal', fontWeight:600, fontSize:'12px',  lineHeight:'12px', color:'#181818' },
  companyParentStyle: {fontFamily:'Arial', fontStyle:'normal', fontWeight:600, fontSize:'12px',  lineHeight:'12px', color:'#181818', margin:'2px'},
  companyChildStyle: {fontFamily:'Arial', fontStyle:'normal', fontWeight:600, fontSize:'10px',  lineHeight:'13px', color:'#646464' }
}