import { axiosService } from './GenericService';
import { localUrl, remoteUrl } from '../url/UrlList';
import { config as appConfiguration } from '../config/Configuration';

class AuthTokenService {
    appMode = appConfiguration.localMode;

    _config = {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Control-Allow-Origin': '*',
        },
    };

    getAuthToken() {
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${localUrl.authToken}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.authToken}`;
            return axiosService.get(url, this._config);
        }
    }

    getCdsAuthToken() { // Pass access_token as bearer & id_token in header - Response will get Roles & Permission
        if (this.appMode) {
            const url = `${localUrl.baseUrl}${'/RolesAndPermission'}`;
            return axiosService.get(url);
        } else {
            const url = `${remoteUrl.cdsAuthTokenApi}`;
            return axiosService.get(url, this._config);
        }
    }

    getOfflineService() {
        const url = 'https://bec-cds.free.beeceptor.com/getcdstesttoken';
        return axiosService.get(url, this._config);
    }
}

const authTokenService = new AuthTokenService();
export { authTokenService };