import axios from 'axios';

let axiosService = axios;

let authToken, appRolesToken, idToken;

axiosService.interceptors.request.use((req) => {
  if (req.method === 'get' || req.method === 'post' || req.method === 'delete' || req.method === 'put') {
    authToken = sessionStorage.getItem('access_token');
    appRolesToken = sessionStorage.getItem('appRolesPermissions');
    idToken = sessionStorage.getItem('id_token');

    if (!req.url.includes('getCdsAuthToken')) { 
      req.headers.authorization = `Bearer ${authToken}`;
      req.headers.IdToken = `${idToken}`;
      req.headers.AppRolesPermissions = `${appRolesToken}`;
    } else{
      req.headers.authorization = `Bearer ${authToken}`;
      req.headers.IdToken = `${idToken}`;
    }
  }

  return req;
});

export { axiosService };
