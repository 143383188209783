import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

const buttonStyle = {
    p: '1rem',
    justifyContent: "center"

}



const ConfirmModal = ({ title, proceed, children, cancelModal, open, handleClose, width = 'auto' }) => {
    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={
            { ...style, width }} >
            <Typography id="modal-modal-title"
                variant="h6"
                component="h2" >
                <b> {title} </b> </Typography>
            <Typography id="modal-modal-description"
                sx={{ mt: 2 }} > {children}
            </Typography>
            <Box sx={buttonStyle} > {
                title !== 'ABOUT' ? <> <Button variant="contained"
                    size='small'
                    onClick={proceed}
                    style={
                        { "margin-right": '1rem' }} >
                    Proceed </Button>
                    <Button variant="contained"
                        size='small'
                        onClick={cancelModal} >
                        Cancel </Button> </> :
                    <Button variant="contained"
                        size='small'
                        onClick={proceed} >
                        Ok </Button>}
            </Box>
        </Box>
    </Modal>
}


export default ConfirmModal;