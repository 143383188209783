import React from "react";
import { unauthorizedStyles } from '../Globalstylesheet'

const UnauthorizedPage = () => {
    return (
        <h2 style={unauthorizedStyles.header}>Your not authorized to access this page!</h2>
    )
};

export default UnauthorizedPage;
