import React, { useCallback } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Tooltip,
  MenuItem,
  Grid
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMsal } from "@azure/msal-react";
import logo from '../../../assets/images/beckman-coulter-logo-white.png';
import { AboutStyles, HeaderStyles } from '../../../Globalstylesheet';
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal';

const settings = ["About", 'Logout'];
const Header = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { instance, accounts } = useMsal();
  const [open, setOpen] = React.useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const currentDate = new Date();
  let currentYear = currentDate?.getFullYear();
  const handleOpenProfileMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseProfileMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleLogout = useCallback((instance) => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/"
    }).catch(e => {
      console.error(e);
    });
  }, [instance])

  const handleAbout = useCallback(() => {
    handleClose();
    handleOpen();
  }, [])

  const proceed = useCallback(() => {
    handleClose();
  }, [])

  const cancelModal = useCallback(() => {
    handleClose();
  }, []);

  return (
    <AppBar position='fixed'>
      <Container maxWidth="false">
        <Toolbar disableGutters sx={HeaderStyles.toolbar}>
          <button
            style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            onClick={handleAbout}
          >
            <img
              src={logo}
              alt="beackman coulter"
              style={{ height: 20 }}
            />
          </button>


          <Box
            sx={HeaderStyles.Box1}
          ></Box>
          <Box
            sx={HeaderStyles.MgmntConsoleBox}
          >
            Management Console
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Typography style={{ display: 'inline' }}>{accounts[0]?.name}</Typography>
            <Tooltip title="Open Profile">
              <IconButton onClick={handleOpenProfileMenu} sx={{ p: 0 }}>
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={HeaderStyles.menu}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseProfileMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseProfileMenu}>
                  {setting == 'Logout' && <Typography textAlign="center" onClick={handleLogout}>{setting}</Typography>}
                  {setting == 'About' && <Typography textAlign="center" onClick={handleAbout}>{setting}</Typography>}

                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <ConfirmModal
        title={'ABOUT'}
        proceed={proceed}
        open={open}
        handleClose={handleClose}
        cancelModal={cancelModal}
        width='25%'
       >
        <Box sx={AboutStyles.aboutContainerStyles}>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item xs={12} sx={{ textAlign: 'left'}}>
              <p style={AboutStyles.softwareParentStyle}>SOFTWARE</p>
              <p style={AboutStyles.softwareChildStyle}>AMP Version: {window?.REACT_APP_AMP_SOFTWARE_VERSION}</p> 
              <p style={AboutStyles.softwareChildStyle}>Platform Version: {window?.REACT_APP_PLATFORM_VERSION}</p> 
              <p style={AboutStyles.softwareChildStyle}>Part Number: {window?.REACT_APP_PART_NUMBER}</p> 
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'left'}}>
              <p style={AboutStyles.companyParentStyle}>{process.env.REACT_APP_COMPANY_NAME}</p>
              <p style={AboutStyles.companyParentStyle}>{process.env.REACT_APP_COMPANY_ADDRESS}</p>
              <p style={AboutStyles.companyParentStyle}>{process.env.REACT_APP_COMPANY_PHONENUMBER}</p>
              <p style={AboutStyles.companyChildStyle}>{"© "+currentYear}{process.env.REACT_APP_COMPANY_SIGNATURE}</p>
            </Grid>
          </Grid>
        </Box>
      </ConfirmModal>
    </AppBar>
  );
};

export default Header;
