export const DataHarmonizationUtils = {
  adminUser: 'beckman.admin',
  generalUser: 'general',
  readOnlyUser: 'readonly',

  biomarkerList: (list) => {
    return list.map((v) => {
      return {
        value: v.biomarkerName,
        label: v.biomarkerName,
        unit: v.targetunit,
      };
    });
  },

  ehrUnitList: (list) => {
    return list.map((v) => {
      return {
        value: v.fromEhrUnit,
        label: v.fromEhrUnit,
      };
    });
  },
};

export const OnScreenTexts = {
  logInMessage: ' has successfully logged in.',
  toasterType: 'info',
};

export function isAdminUser(role) {
  return role === DataHarmonizationUtils.adminUser;
}

export function isGeneralUser(role) {
  return role === DataHarmonizationUtils.generalUser;
}

export function isreadOnlyUser(role) {
  return role === DataHarmonizationUtils.readOnlyUser;
}




export function decimalYearsToDuration(decimalYears) {
  let stringifiedVal = decimalYears.toString();

  let years = Number(stringifiedVal.split('.')[0]),
    months = Number(stringifiedVal.split('.')[1]);

  if (isNaN(months)) {
    months = 0;
  }

  return {
    years: years,
    months: months,
    weeks: 0,
    days: 0,
    hours: 0,
  };
}

export function durationToDecimalYears(duration) {
  let _years = duration.years,
    _months = duration.months;

  const totalYears =
    duration.months === 12 ? _years + 1 : _years + '.' + _months;
  if (duration.months === 12) {
    return totalYears;
  } else {
    return duration.months > 9
      ? Number(totalYears).toFixed(2)
      : Number(totalYears).toFixed(1);
  }
}

export function monthsToYearsMonths(_months) {
  return {
    years: Math.floor(_months / 12),
    months: _months % 12,
  };
}

export const isObjectEmpty = (_obj) => Object.entries(_obj).length > 0;

export const API_CODES = {
  SUCCESS: 200,
  NOT_FOUND: 400,
  UNAUTHORIZED: 401,
};

export const ApplicationColumns = [
  {
    field: 'applicationName',
    headerName: 'Name',
    flex: 1,
    editable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'applicationVersion',
    headerName: 'Version',
    flex: 1,
    editable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'platformVersion',
    headerName: 'Platform Version',
    flex: 1,
    editable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'applicationDescription',
    headerName: 'Description',
    flex: 1,
    editable: false,
    headerClassName: 'super-app-theme--header',
  },
];

export const createOptions = (min, max) => {
  let valArr = [];
  for (let i = min; i <= max; i++) {
    valArr.push({ label: i, value: i });
  }
  return valArr;
};

export let ThresholdDataFormat = {};

export const appTypes = {
  MACE: 'mace',
  trueFLag: true,
  falseFlag: false
}

export const DefaultThresholdRanges = {
  low: { max_1: 2, max_2: 3 },
  medium: { min_1: 3, min_2: 4 },
  warningTextMsg1: `You are about to change the MACE risk categories for `,
  warningTextMsg2: `The revised risk categories will be used for all new patients. This change should be made only at the request of the clinical administrator. You cannot undo this change.`,
  categoryInfoTextMsg: `Risk Category Configuration should only be changed at the request of the Customer Clinical Administrator.`,
  originalRiskMsg: `Original Risk Categories:`,
  revisedRiskMsg: `Revised Risk Categories:`
}


export const getMinMaxRange = (rangeArr, category) => {
  return {
    min: rangeArr?.filter((element) => (element.label).toLowerCase() === category)[0]?.min,
    max: rangeArr?.filter((element) => (element.label).toLowerCase() === category)[0]?.max,
  }
}