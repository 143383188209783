import React,{useContext} from "react";
import { UserContext } from "./Provider";
import UserContextRef from "./UserContext";
   
const Authorization = (WrappedComponent, allowedRoles) => {
    return class WithAuthorization extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                user: {
                    admin: 'beckman.admin',
                    general:'general',
                    readonly : 'readonly'
                }
            }
        }

        render() {

            const { admin, general, readonly } = this.state.user;
            
            const authorizeduser =  allowedRoles?.includes(admin) || allowedRoles?.includes(general) || allowedRoles?.includes(readonly);

            return <UserContextRef authorizeduser={authorizeduser} allowedRoles={allowedRoles}><WrappedComponent {...this.props} /></UserContextRef>
        }
    }
}

export const UserContextState = () => {
    return useContext(UserContext);
  };

export default Authorization;