import React from 'react';
import Footer from './navigation/footer/Footer';
import Header from './navigation/header/Header';
import MenuBar from './navigation/menu-bar/MenuBar';
import ShellComponent from './navigation/shell/ShellComponent';
import { UserContext } from '../components/Auth/Provider';
import UnauthorizedPage from './UnauthorizedPage';


const Layout = (props) => {
  return (
    <div>
      <UserContext.Consumer>
        {({ authorizeduser }) => (
          authorizeduser ? <>
            <Header />
            <MenuBar />
            <ShellComponent {...props} />
            <Footer />
          </> : <>
            <Header />
            <UnauthorizedPage />
            <Footer />
          </>
        )}
      </UserContext.Consumer>
    </div>
  );
};

export default Layout;

